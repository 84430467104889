.sectionHeader .headingBlock p {
  max-width: 800px;
  margin: 0 auto;
}
.howWeHelp .sectionHeader p{
  max-width: 800px;
  margin: 0 auto;
}
section {
  padding: 0 15px;
}

p {
  max-width: 100%;
  margin: auto;
}

.stepList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 1270px;
  padding: 0 15px;
  margin: 80px auto 0;
  @media screen and (width <= 1440px){
    max-width: 1200px;
  }
}
.stepCard {
  flex: 0 0 33%;
  max-width: calc(100% / 3 - 16px);
  text-align: center;
  background: var(--commonLight);
  border-radius: 32px;
  padding: 40px 40px;
  border: 1px solid rgba(38, 42, 186, 0.1);
  position: relative;
  @media screen and (max-width:992px){
    flex: 0 0 50%;
  max-width: calc(100% / 2 - 16px);
  }
  @media screen and (max-width:767px){
    padding: 15px;
  }
}
.stepCard:not(:first-child, :last-child) .infoBlock h3 span {
  display: block;
}
.stepCard:last-child h3{
  font-family: Sora;
  font-size: 40px;
  font-weight: 700;
  line-height: 50.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  @media screen and (max-width:1199px){
    font-size: 30px;
  }
}
.iconsBlock {
  width: 178px;
  height: 178px;
  margin: 0 auto 16px;
}

.countBlock {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: 0;
}

.countBlock p {
  font-family: Sora;
  font-size: 197px;
  font-weight: 400;
  line-height: 242.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgb(38 42 186 / 10%);
}
.iconsBlock {
  width: 178px;
  height: 178px;
  margin: 0 auto 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}
.infoBlock p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}
.infoBlock h3 {
  text-transform: uppercase;
  color: rgba(33, 33, 33, 1);
}
.infoBlock h3 span {
  color: var(--marketplaceColor);
}

.iconsBlock img {
  height: 178px;
  object-fit: contain;
}
.designDifferent {
}


@media screen and (max-width:575px){
  .stepList{
    display: grid

  }
  .stepCard{
   
    max-width: 100%;
}
}