.headingBlock{
   max-width: 800px;
   margin: 0 auto;
   text-align: center; 

}
.headingBlock h2{
   margin: 16px 0 24px;
}

.headingBlock h2 span{
   color: var(--marketplaceColor);
}

.headingBlock h3{
   margin: 16px 0 24px;
}

.headingBlock h3 span{
   color: var(--marketplaceColor);
}
.customclass{
   max-width: 1040px;
}