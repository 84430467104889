.popularLocation{
    padding: 100px 15px;
    background-color: var(--marketplaceColor);
    margin-top: 80px;
}

.locationsSection{
    max-width: 1270px;
    padding: 0 15px;
    margin:  auto;
    width: 100%;
    @media screen and (width <= 1440px){
        max-width: 1200px;
      }
}   


.locationHeading *{
    color: #ffffff;
    text-align: left;
  
}
.locationHeading {   
    display: grid;
    grid-template-columns: repeat(2,1fr);
     gap: 40px;
     margin-bottom: 100px;
}
.locationHeading h2  span{
    display: block;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: normal;
}

.leftSideHeading{
    
}
.rightSideContent{

}
.rightSideContent p{   
    font-family: Sora;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 32px;
    
}
.locationsGrid{
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    .locationCard{
        flex: 0 0 33%;
        max-width: calc(100% / 3 - 32px); 
        .locationImage{
            margin-bottom: 24px;
        }
        p{
            font-family: Sora;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #ffffff;
            padding-left:16px;
            border-left: 1px solid rgba(255, 188, 66, 1);
        
        }
    }
    @media screen and (max-width:992px){
        gap: 15px;
        .locationCard{           
            max-width: calc(100% / 3 - 10px); 
            .locationImage{
               
            }
            p{
              font-size: 18px;
            
            }
        }
    }
    @media screen and (max-width:767px){
        display: grid;
        gap: 50px;
        .locationCard{           
            max-width: 100%;
            flex: 0 0 100%;
            .locationImage{
                width: 100%;
            }
            p{
              font-size: 18px;
            
            }
        }
    }
}



.locationImage img{
    width: 100%;
}

@media screen and (max-width:991px){
    .locationHeading h2  span{       
        font-size: 18px;
    }
}

@media screen and (max-width:767px){
    .locationHeading {         
        grid-template-columns: repeat(1,1fr);
        gap: 0;
    }
    .rightSideContent p{   
      
        font-size: 14px;
        
    }
}