.careCommunityOptions {
  .careCommunity_wrapper {
    padding: 80px 0;
    border-bottom: 1px solid var(--marketplaceColor);
    .titleSection {
      h2 {
        span.colorhigh {
          color: var(--marketplaceColor);
          display: block;
        }
      }
      p {
        line-height: 27px;
        max-width: 720px;
        padding: 0 15px;
      }
    }

    .optionButton {
      border: 1px solid var(--marketplaceColor);
      color: var(--marketplaceColor);
      width: 100%;
      min-height: 56px;
      padding: 10px 30px;
      max-width: max-content;
      border-radius: var(--borderCommon);
      gap: 12px;
      transition: 0.3s ease all;
      cursor: pointer;
      &:hover {
        color: #fff;
        background: var(--marketplaceColor);
      }
    }
    .optionsSection {
      gap: 16px;
      margin-bottom: 48px;
      margin-top: 48px;
    }
  }
  @media screen and (max-width: 1199px) {
    .careCommunity_wrapper {
      .optionButton {
        padding: 10px 15px;
      }
      .optionsSection {
      }
    }
  }
  @media screen and (max-width: 991px) {
    .careCommunity_wrapper {
      padding: 30px 0;
      .optionButton {
      }
      .optionsSection {
        flex-wrap: wrap;
      }
    }
  }
}
