.careConnectionSection {
  background-color: var(--marketplaceColor);
  padding: 64px 15px;
}

.titleSection {
 .headingBlock{
    max-width: 1040px;
  }
}
.titleSection * {
  color: #ffffff;
}
.titleSection h2 {
    max-width: 980px;
    margin: auto;
}
.titleSection p {
  max-width: 100%;
  margin: 24px 0;
  font-family: Sora;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.buttonSection {
  text-align: center;
}
