.bannerComp {
  /* display: inline; */
  background: var(--marketplaceColor);
  padding-top: 120px;
  .wrapperBanner {
    grid-template-columns: 520px 1fr;
    gap: 40px;
    padding-bottom: 100px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 767px) {
      gap: 0px;
    }
  }
  h1,
  p {
    color: #fff;
  }
}

.highlight {
  background: rgb(255, 188, 66);
  background: linear-gradient(
    90deg,
    rgba(255, 188, 66, 1) 0%,
    rgba(254, 172, 72, 1) 30%,
    rgba(252, 105, 95, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 10s 1s infinite linear;
}

.counter {
  grid-template-columns: 1fr 1fr;
  grid-column: span 2;
  flex: 0 0 40%;
}

.statSection {
  grid-template-columns: 631px 1fr;
}

.colorD {
  color: var(--commonHover);
}

.stat {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 325px;
  padding: 100px 0;
  align-items: flex-start;


  h2,
  p {
    margin: 0;
    padding: 0;
  }

  p {
    font-weight: 400;
  }

  h2 {
    font-size: 56px;
    color: var(--colorWhite);
  }
}

.containerCounter {
  position: relative;
}
.containerCounter:after {
  content: '';
  position: absolute;
  width: 100%;
  background: #e8f5ff;
  height: 100%;
  top: 0;
  max-width: 50%;
  right: 0;
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.quoteSection {
  background: var(--commonLight);
  padding: 0 0px 0px 101px;
  place-content: center;
  /* grid-column: span 3; */
  z-index: 1;
  flex: 1;
}
.container-counter:after {
  content: '';
  position: absolute;
  width: 50%;
  background: #e8f5ff;
  height: 100%;
  top: 0;
  right: 0;
}

.contentquote {
  grid-template-columns: auto 1fr;
  gap: 30px;
  max-width: 630px;

  blockquote {
    margin: 0;
    font-size: 20px;
    color: var(--colorBlack);
    font-weight: 400;
    line-height: 32px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 25px;
    }
  }
}

.container-counter {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--gutter-x, 0.75rem);
  padding-left: var(--gutter-x, 0.75rem);
}

@keyframes hue {
  from {
    filter: hue-rotate(0deg);
  }

  to {
    filter: hue-rotate(-360deg);
  }
}

.wrapperSearch {
  border: 1px solid #ffffff40;
  border-radius: 18px;
}

.statSection {
  max-width: 1270px;

  /* max-width: 1630px; */
  padding: 0 15px;
  margin: 0 auto;
  /* grid-template-columns: repeat(5, 1fr); */
  display: flex;
  gap: 50px;
  justify-content: center;
 
  @media screen and (width <= 1440px) {
    max-width: 1200px;
  }
}
.container-counter {
  position: relative;
}

.wrapperSearch .searchinput {
  display: flex;
  background: #fff;
  border-radius: 16px;
  padding: 4px;
  
}
.rightBannerContent p {
  line-height: 36px;
  margin-bottom: 37px;
  text-align: left;
  @media screen and (max-width: 991px) {
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: 991px) {
    line-height: 25px;
  }
}

.formField {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  flex: 1;
  form {
    display: flex    ;
            width: 100%;
            justify-content: space-between;
    input {
      background-image: unset !important;
      border: 0 !important;
      box-shadow: unset !important;
      height: 50px;
      font-size: 16px;
    }
    @media screen and (width < 1200px){
      /* flex-direction: column;  */
    }
  }
  &:first-child{
    max-width: 45%;
    @media screen and (width < 1200px){
      max-width: 100%;
    }
  }
}

.formField input {
  border-radius: 16px;
  border: 0;
  box-shadow: unset;
}

input::placeholder {
  color: #262bba80;
}

.formField:first-child:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 24px;
  background: #262bba;
  right: 0;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.container-counter {
  max-width: 1217px;
  margin: auto;
}

@media screen and (max-width: 1400px) {
  .statSection {
    max-width: 95%;
    padding: 0 15px;
  }
}

@media screen and (max-width: 1199px) {
  .wrapperSearch .searchinput {
    flex-direction: column;
    gap: 5px;
  }
  .formField:first-child:after {
    width: 100%;
    height: 1px;
    bottom: 0;
  }
  .quoteSection {
    background: var(--commonLight);
    padding: 0 0px 0px 20px;
    place-content: center;
    /* grid-column: span 3; */
    z-index: 1;
    flex: 1 1;
  }
  .statSection {
    max-width: 92%;
    margin: 0 auto;
  }
  .stat {
    h2 {
      font-size: 40px;
      color: var(--colorWhite);
    }
  }
}

@media screen and (max-width: 991px) {
  .quoteSection {
    margin: 0 -15px;
  }
  .wrapperSearch .searchinput {
    flex-direction: row;
  }
  .formField:first-child:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 24px;
    background: #262bba;
    right: 0;
    top: 15px;
  }
  .statSection {
    flex-direction: column;
    max-width: 100%;
    padding-top: 25px;
  }
  .contentquote {
    padding: 30px 0;
  }
  .stat {
    padding: 0;
    min-height: unset;
    align-items: center;
    h2 {
      line-height: normal;
    }
  }
}

@media screen and (max-width: 575px) {
  .quoteSection {
    padding: 0 15px;
  }
  .contentquote svg {
    width: 25px;
  }
  .wrapperSearch .searchinput {
    flex-direction: column;
  }
  .formField:first-child:after {
    display: none;
  }
  .formField input {
    height: 50px;
  }
}
